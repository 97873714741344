import logo from "./logo.svg";
import "./App.css";
import Header from "./Components/Header/Header";
import Landing_page from "./Components/Landing_page/Landing_page";
import About_eth from "./Components/About_eth/About_eth";
import Buy_eth from "./Components/Buy_eth/Buy_eth";
import Roadmap from "./Components/Roadmap/Roadmap";
import Whitepaper from "./Components/Whitepaper/Whitepaper";
import Footer from "./Components/Footer/Footer";
import FAQ from "./Components/FAQ/FAQ";
import COMPARISONS from "./Components/COMPARISONS/COMPARISONS";
import Staking_page from "./Components/Staking_page/Staking_page";
import Tab_stake from "./Components/Tab_stake/Tab_stake";
import { Route, Routes } from "react-router-dom";
import Home_page from "./Components/Home_page/Home_page";
import { Toaster } from "react-hot-toast";

function App() {
  return (
    <div className="App">
      <div className="overlay"></div>
      <div>
        <Toaster />
        <Header />

        <Routes>
          <Route path="/" element={<Staking_page />} />
          {/* <Route path="/staking_page" element={<Staking_page />} /> */}
        </Routes>

        <Footer />
      </div>
    </div>
  );
}

export default App;
