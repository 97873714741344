import React, { useEffect, useState } from "react";
import "./Staking_page.css";
import Tab_stake from "../Tab_stake/Tab_stake";
import Tab_stake_V1 from "../Tab_stake/Tab_stake_V1";
import { useAccount } from "wagmi";
import {
  Staking_ABI,
  Staking_Address,
  Old_Staking_Address,
  Old_Staking_ABI,
  Staking_Token_ABI,
  Staking_Token_Address,
} from "../../util/Contract";
import Web3 from "web3";

export default function Staking_page() {
  const { address } = useAccount();
  const [Token_BalanceOF, setToken_BalanceOF] = useState(0);
  const [totalStaking, settotalStaking] = useState(0);
  const [OldTotalStaking, setOldTotalStaking] = useState(0);
  const [DepositeToken, setDepositeToken] = useState(0);
  const [totalReward, settotalReward] = useState(0);
  const [totalfutureReward, settotalfutureReward] = useState(0);
  const [minStake, setminStake] = useState(0);
  
  const [spinner_Red, setspinner_Red] = useState(false);

  const webSupply = new Web3("wss://ethereum.publicnode.com");

  const Token_Balance = async () => {
    let contractOf_Token = new webSupply.eth.Contract(
      Staking_Token_ABI,
      Staking_Token_Address
    );

    if (address) {
      let balance = await contractOf_Token.methods.balanceOf(address).call();
      balance = webSupply.utils.fromWei(balance.toString())
      // balance = balance.toString();
      setToken_BalanceOF(parseFloat(balance).toFixed(2));

      let Token_balance = await contractOf_Token.methods
        .balanceOf(Staking_Address)
        .call();
      Token_balance = webSupply.utils.fromWei(Token_balance.toString())
      Token_balance = Token_balance.toString();

      //Old staking contract balance
      let Old_Token_balance = await contractOf_Token.methods
        .balanceOf(Old_Staking_Address)
        .call();
      Old_Token_balance = webSupply.utils.fromWei(Old_Token_balance.toString())
      Old_Token_balance = Old_Token_balance.toString();

      let totalBalance = Number(Token_balance) + Number(Old_Token_balance);

      settotalStaking(parseFloat(totalBalance).toFixed(3));
      setOldTotalStaking(parseFloat(Old_Token_balance).toFixed(3));
    }
  };

  useEffect(() => {
    let interval = setInterval(() => {
      Token_Balance();
    }, 1000);
    return () => clearInterval(interval);
  }, [address]);

  const Read_Fuc = async () => {
    try {
      let contractOf = new webSupply.eth.Contract(Old_Staking_ABI,Old_Staking_Address);

      if (address) {
        let Users = await contractOf.methods.Users(address).call();
        Users = webSupply.utils.fromWei(Users.DepositeToken.toString())
        Users = Users.toString();
        console.log(parseFloat(Users).toFixed(2),"Users"); 
        setDepositeToken(parseFloat(Users).toFixed(2))
        setspinner_Red(false);
      }
      setspinner_Red(false);
    } catch (error) {
      setspinner_Red(false);

      console.log(error);
    }
  };

  useEffect(() => {
    Read_Fuc();
  }, [address]);
  return (
    <>
    <div className={DepositeToken > 0 ? 'sm:flex sm:flex-row' : ''}>
    {DepositeToken > 0 ? <><div className="main_staking_page">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-12">
            <div className="main_box_staking">
              <h1>$INQU STAKING<span className="text-primary">
              {" "}V1
                  </span></h1>
              <p className="stk_para">Total in $INQU Staking</p>
              <p className="tota_de">
                {" "}
                <span className="fw-bold new_clr">{OldTotalStaking} $INQU</span>
              </p>
              {/* <h5>APY: 120%</h5> */}
              <Tab_stake_V1 Token_BalanceOF={Token_BalanceOF} />
            </div>
          </div>
        </div>
      </div>
    </div></>:<></>}
    <div className="main_staking_page">
      <div className="container">
       <div className="row justify-content-center">
         <div className={DepositeToken > 0 ? 'col-md-12' : 'col-md-7'}>
           <div className="main_box_staking">
             <h1>$INQU STAKING<span className="text-primary">
             {" "}V2
                  </span></h1>
             <p className="stk_para">Total in $INQU Staking</p>
             <p className="tota_de">
               {" "}
               <span className="fw-bold new_clr">{totalStaking} $INQU</span>
             </p>
             {/* <h5>APY: 120%</h5> */}
             <Tab_stake Token_BalanceOF={Token_BalanceOF} />
           </div>
         </div>
       </div>
       </div>
      </div>
      </div>
   </>
  );
}
